export default (self) => {
  return [
    {
      value: "fullname",
      text: "Name",
      sortable: true,
      filterable: true,
      width: 160,
    },
    {
      value: "zohoId",
      text: "Zoho",
      sortable: false,
      filterable: false,
      width: 40,
      align: "center",
      toStringFn: (item, key) =>
        item[key] &&
        `${self.$config.public.zohoCrmUrl}/tab/Contacts/${item[key]}`,
    },
    {
      value: "demo",
      text: "Demo",
      sortable: false,
      filterable: false,
      width: 40,
      align: "center",
    },
    {
      value: "email",
      text: "Email",
      sortable: true,
      filterable: true,
      width: 200,
    },
    {
      value: "apiId",
      text: "API ID",
      sortable: true,
      filterable: true,
      visible: false,
    },
    {
      value: "apiKey",
      text: "API Key",
      sortable: true,
      filterable: true,
      visible: false,
    },
    {
      value: "persona",
      text: "Persona",
      sortable: true,
      filterable: true,
      width: 75,
    },
    {
      value: "companyName",
      text: "Company",
      sortable: true,
      filterable: true,
      width: 250,
    },
    {
      value: "telephone",
      text: "Telephone",
      sortable: true,
      filterable: true,
      width: 110,
    },
    {
      value: "features",
      text: "Features access",
      sortable: true,
      width: 200,
    },
    {
      value: "lastEventCreatedAt",
      text: "Last Seen",
      visible: true,
      width: 100,
      toStringFn: (item) => self.formatDate(item["lastEventCreatedAt"]),
    },
    {
      value: "lastEventName",
      text: "Last Event name",
      visible: true,
      width: 120,
      toStringFn: (item) => item["lastEventName"],
    },
    {
      value: "lastEventOs",
      text: "OS",
      visible: true,
      width: 75,
      toStringFn: (item) => item["lastEventOs"],
    },
    {
      value: "lastEventBrowser",
      text: "Browser",
      visible: true,
      width: 200,
      toStringFn: (item) => item["lastEventBrowser"],
    },
    {
      value: "lastEventIpAddress",
      text: "IP",
      visible: true,
      width: 110,
      toStringFn: (item) => item["lastEventIpAddress"],
    },
    {
      value: "projects",
      text: "Projects",
      visible: true,
      width: 200,
    },
    {
      value: "projectsCount",
      text: "# Projects",
      sortable: true,
      filterable: true,
      align: "center",
      visible: true,
      width: 85,
    },
    {
      value: "camerasCount",
      text: "# Cameras",
      sortable: true,
      filterable: true,
      align: "center",
      visible: true,
      width: 85,
    },
    {
      value: "integrations",
      text: "Connectors",
      sortable: true,
      filterable: true,
      width: 60,
      visible: true,
      align: "center",
    },
    {
      value: "eventsCount",
      text: "# Events",
      sortable: true,
      filterable: true,
      align: "center",
      visible: true,
      width: 85,
    },
    {
      value: "camerasOwned",
      text: "# Owned",
      sortable: true,
      filterable: true,
      align: "center",
      width: 85,
    },
    {
      value: "cameraShares",
      text: "# Shared",
      sortable: true,
      filterable: true,
      align: "center",
      width: 85,
    },
    {
      value: "snapmailCount",
      text: "# Progress Photos",
      sortable: true,
      filterable: true,
      align: "center",
      width: 90,
    },
    {
      value: "sessionCount",
      text: "# Session",
      sortable: true,
      filterable: true,
      align: "center",
      width: 85,
    },
    {
      value: "countryName",
      text: "Country",
      sortable: true,
      filterable: true,
      width: 140,
    },
    {
      value: "createdAt",
      text: "Create Date",
      sortable: true,
      filterable: true,
      width: 135,
      toStringFn: (item, key) => self.formatDate(item[key]),
    },
    {
      value: "lastLoginAt",
      text: "Last Login Date",
      sortable: true,
      filterable: true,
      width: 135,
      toStringFn: (item, key) => self.formatDate(item[key]),
    },
    {
      value: "referralUrl",
      text: "Referral URL",
      sortable: true,
      filterable: true,
      width: 300,
    },
  ]
}
